<script>
import { ref } from "vue";
import useVuelidate from "@vuelidate/core";

// import DropZone from "@/components/widgets/dropZone";
// import datepicker from "vue3-datepicker";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { Api } from "../../../helpers";
import { Erreur } from "../../../helpers/error";
import loaderProcessVue from '../../../components/widgets/loaderProcess.vue';
/**
 * Projects-create component
 */
export default {
  page: {
    title: "Create New Project",
    meta: [{ name: "description", content: appConfig.description }],
  },
  setup() {
    let dropzoneFile = ref("");
    const drop = (e) => {
      dropzoneFile.value = e.dataTransfer.files[0];
    };
    const selectedFile = () => {
      dropzoneFile.value = document.querySelector(".dropzoneFile").files[0];
    };
    return { dropzoneFile, drop, selectedFile, v$: useVuelidate() };
  },
  components: {
    // DropZone,
    // datepicker,
    Layout,
    PageHeader,
    loaderProcessVue,
  },
  data() {
    return {
      titre:"",
      description:"",
      url:"",
      channels:[],
      process:false,
      title: "Modifier la chaine",
      items: [
        {
          text: "Chaine",
          href: "/projects/channels/",
        },
        {
          text: "Modifier",
          active: true,
        },
      ],
      // dropzoneOptions: {
      //   url: "https://httpbin.org/post",
      //   thumbnailWidth: 150,
      //   maxFilesize: 0.5,
      //   headers: { "My-Awesome-Header": "header value" },
      // },
      // daterange: ref(new Date()),
    };
  },
  methods:{
    logoChannel(event){
      this.imagePSelectFile = event.target.files[0]
    },
    updateChannel(){
      this.process = true

        Api.putFormData("/streamvod/rest/channel/update/"+ this.$route.params.id +"?channel_description=" + this.description + "&channel_name=" + this.titre + "&channel_url=" + this.url, {
          file: this.imagePSelectFile
        })
        .then(() =>{
          this.process = false
        this.$router.push("/projects/channels");
        }).catch((error) => {
        this.process = false
        Erreur.gestionErreur(error.message)
      })
    }
  },
  async mounted(){
          
    const result = await Api.get('/streamvod/rest/channel/{id}?id='+this.$route.params.id)
    this.channels=result.data.content
    this.titre = this.channels.channel_name
    this.description = this.channels.channel_description
    this.url = this.channels.channel_url
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">


        <div class="card" style="padding: 25px; width: 65%; margin-left: auto; margin-right: auto; border-radius: 15px;">
          <div class="card-body">
            <h4 class="card-title mb-4">{{ $t('pages.updateChannel.formulaire.title') }}</h4>
            
          </div>
        </div>

      </div>
      <loaderProcessVue v-if="process == true"></loaderProcessVue>
    <!-- end row -->
  </Layout>
</template>

<style>
  .select-action{
    display: flex;
    justify-content: space-between;
  }
  .select-action p{
    text-align: center;
    width: 100%;
    font-size: 1.3em;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .select-action p:hover{
    transform: scale(1.2);
  }
  .borderB{
    border-bottom: 2px solid #556ee6;
  }
</style>